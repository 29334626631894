<div class="footer">
  <div class="quick-menu mobile-view">
    <ul class="">
			<ng-container *ngIf="isLogin$ | async as isLoggedIn; else notLoggedIn">
      <li><a (click)="setLogout()">로그아웃</a></li>
      <li><a routerLink="/mypage/modify">회원정보수정</a></li>
      <li><a routerLink="/mypage/dashboard">마이페이지</a></li>
      <li><a (click)="setChangeViewPC()">PC버전</a></li>
			</ng-container>
			<ng-template #notLoggedIn>
				<li><a routerLink="/member/login" >로그인</a></li>
				<li><a routerLink="/member/find/id">ID/비밀번호찾기</a></li>
				<li><a routerLink="/member/join">회원가입</a></li>
				<li><a (click)="setChangeViewPC()">PC버전</a></li>
			</ng-template>
    </ul>
  </div>
  <div class="footer1 inner clearfix">
    <div>
      <p>고객센터</p>
      <ul>
	      <li class="tel">1544-5022</li>
        <li>고객센터 운영시간</li>
        <li>온라인 10:00 ~ 18:00</li>
        <li>점심시간 12:00 ~ 13:00 제외</li>
        <li>토, 일, 공휴일 휴무</li>
        <li></li>
        <li>매장 운영시간</li>
	      <li>대구점 평일 AM 10:00 - PM 18:00</li>
	      <li>서울점 평일 AM 10:00 - PM 18:30, ☎ 1544-5660</li>
	      <!-- <li>대구점/ 서울점 일요일, 공휴일 휴무</li> -->
				<li>대구점/서울점 일요일 휴무(행사 시 근무), 공휴일 근무(단, 설날, 추석 제외)</li>
      </ul>
    </div>

    <div class="pc-view">
      <ul>
        <li><a routerLink="/company/info">회사소개</a></li>
        <li><a routerLink="/company/society">동호회 등록 신청</a></li>
        <li><a routerLink="/company/supply">제휴/입점문의</a></li>
        <li><a routerLink="/company/story">창업스토리</a></li>
        <li><a routerLink="/company/contact">가맹문의</a></li>
        <li><a routerLink="/company/policy/service">쇼핑몰이용약관</a></li>
        <li><a routerLink="/company/policy/privacy">개인정보취급방침</a></li>
        <li><a [href]="scmURL" target="_blank">입점업체전용</a></li>
				<li><a routerLink="/company/sena/registraion">세나정품등록</a></li>
      </ul>
    </div>

    <div class="bank-div">
      <p>입금계좌</p>
      <ul>
				<li>우리 1005-001-003981</li>
				<li>국민 801301-01-402818</li>
				<li>기업 152-070701-01-019</li>
				<li>농협 718-01-179021</li>
				<li>제일 550-10-018200</li>
				<li>신한 412-05-024351</li>
				<li>대구 068-12-000626</li>
				<li>우체국 703462-01-001170</li>
				<li>예금주 : (주)바이크마트</li>
      </ul>
    </div>

    <div class="store-div">
      <p>매장안내</p>
      <ul>
	      <li *ngFor="let item of offlineStoreList"><a routerLink="/company/info" [queryParams]="{storeSeq: item.seq, storeName: item.store_name}">{{item.store_name}}</a></li>
	      <!-- <li><a routerLink="/company/info" [queryParams]="{storeSeq: 2, storeName: '대구점'}">대구점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 3, storeName: '서울점'}">서울점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 0, storeName: '서울특약점'}">서울특약점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 13, storeName: '대전점'}">대전점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 14, storeName: '울산점'}">울산점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 18, storeName: '포항점'}">포항점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 0, storeName: '서대구점'}">서대구점</a></li>
	      <li><a routerLink="/company/info" [queryParams]="{storeSeq: 0, storeName: '경기서부점'}">경기서부점</a></li> -->
      </ul>
      <!-- <p></p>
      <ul>
				<li><a routerLink="/company/info">회사소개</a></li>
				<li><a routerLink="/company/supply">제휴/입점문의</a></li>
				<li><a routerLink="/company/story">창업스토리</a></li>
				<li><a routerLink="/company/contact">가맹문의</a></li>
				<li><a routerLink="/company/policy/service">쇼핑몰이용약관</a></li>
				<li><a routerLink="/company/policy/privacy">개인정보취급방침</a></li>
				<li><a [href]="scmURL" target="_blank">입점업체전용</a></li>
      </ul> -->
    </div>
  </div>

  <div class="terms-menu mobile-view">
	  <ul class="clearfix">
      <li><a routerLink="/company/info">회사소개</a></li>
			<li><a routerLink="/company/supply">제휴/입점문의</a></li>
			<li><a routerLink="/company/story">창업스토리</a></li>
			<li><a routerLink="/company/contact">가맹문의</a></li>
		</ul>
		<ul class="clearfix">
			<li><a routerLink="/company/policy/service">쇼핑몰이용약관</a></li>
      <li><a routerLink="/company/policy/privacy">개인정보취급방침</a></li>
	  </ul>
  </div>

	<div class="footer2">
	  <ul class="inner">
	    <li>법인등록명 : (주)바이크마트<span class="line"></span>대표이사 : 최한용</li>
	    <li>사업자등록번호 : 504-81-64470<span class="business-no" (click)="businessPopup();">사업자정보확인</span></li>
	    <li>통신판매업신고번호 : 제2017-대구동구-0590호</li>
	    <li>특별통관대상업체지정번호 : 20080004 (수입대행형)</li>
	    <li>주소 : 대구광역시 동구 신천동 152-3번지 <br class="mobile-view">(도로명주소 : 대구광역시 동구 장등로 27)</li>
	    <li>TEL : 1544-5022<span class="line"></span>FAX : 053-289-3354</li>
	    <li>E-mail : bikemart@daum.net</li>
	    <li>개인정보보호책임자 : 전석환 부장</li>
	    <li>Copyright © 2008 bikemart.co.kr ALL RIGHTS RESERVED.</li>
	  </ul>
	</div>

  <div class="footer3 pc-view">
    <ul class="clearfix">
	    <li><img src="/assets/images/venture_logo.gif"></li>
	    <li><img src="/assets/images/starcompany100.png"></li>
	    <li><img src="/assets/images/top_logo.gif"></li>
	    <li><img src="/assets/images/ftc_logo.png"></li>
	    <li><img src="/assets/images/sgic_logo.png"></li>
    </ul>
  </div>

  <div class="tb-btn">
    <div class="top" (click)="toTop();"></div>
    <div class="bottom" (click)="toBottom();"></div>
  </div>

</div>
